import { formValidation } from "@form-validation/bundle/full";
import { Trigger } from "@form-validation/plugin-trigger";
import { Declarative } from "@form-validation/plugin-declarative";
import { Bootstrap5 } from "@form-validation/plugin-bootstrap5";
import { Message } from "@form-validation/plugin-message";
import { SubmitButton } from "@form-validation/plugin-submit-button";

document.addEventListener("DOMContentLoaded", (event) => {
  const form_login = document.getElementById("formLogin");
  const passwordInput = document.getElementById("inputPassword");
  const btnPassToggle = document.getElementById("btnPassToggle");
  const fv_form_login = formValidation(form_login, {
    fields: {
      username: {
        validators: {
          notEmpty: { message: "Username ไม่เว้นว่าง" },
          regexp: {
            regexp: /^[a-zA-Z0-9_.-]*$/,
            message: "Nu Account หรือ รหัสนิสิต ไม่ต้องใส่ @nu.ac.th",
          },
        },
      },
      password_nu: {
        notEmpty: {
          message: "Password ไม่เว้นว่าง",
        },
      },
      login_type: {
        validators: {
          notEmpty: { message: "โปรดเลือกประเภทผู้ใช้ระบบ" },
        },
      },
    },
    plugins: {
      trigger: new Trigger(),
      declarative: new Declarative({
        html5Input: true,
      }),
      bootstrap5: new Bootstrap5({
        rowSelector: ".fv-mark",
        defaultMessageContainer: false,
      }),
      message: new Message({
        clazz: "text-danger link-offset-1 btn btn-sm small",
        container: "#formInvalidMessage",
      }),
      submitButton: new SubmitButton(),
    },
  });

  fv_form_login.on("plugins.message.displayed", function (e) {
    e.messageElement.addEventListener("click", function () {
      e.element.focus();
    });
  });

  fv_form_login.on("core.form.valid", function (e) {
    form_login.querySelector('[name="submitButton"]').disabled = true;
    form_login.querySelector('[name="submitButton"]').innerHTML = '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>';
    form_login.submit();
  });

  btnPassToggle.addEventListener("click", (e) => {
    ["bi-eye-slash", "bi-eye"].map((v) => e.currentTarget.querySelector("i.bi").classList.toggle(v));
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  });
});
